<template>
  <div class="flex-col">
    <b-tabs type="is-boxed" v-model="mainActiveTab">

      <b-tab-item>
        <template slot="header">
          <b-icon pack="fas" icon="tasks"></b-icon>
          <span> {{ project.name }} </span>
        </template>
        <div>

          <div class="flex justify-end">
            <div class="flex w-7/8">
              <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
                <div class="rounded-full bg-blue w-8 h-8 flex ml-3 items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
                  <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport" />
                  <b-icon
                    pack="fas"
                    icon="sync-alt"
                    custom-class="fa-spin"
                    v-if="isLoadingExport"
                    >
                  </b-icon>
                </div>
              </b-tooltip>
            </div>
          </div>

          <div class="w-full panel">
            <div class="flex">
              <div class="flex w-1/2">
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ $t('date') }}</div>
                  <div class="flex flex-row w-full">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ $t('location') }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Referencia Proyecto' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
              </div>
              <div class="flex w-1/2">
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Tecnología' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'CAPEX' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Potencia (MW)' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex w-1/2">
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Energia (MWh)' }}</div>
                  <div class="flex flex-row w-full">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Conexión Red (kV)' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Promotor' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
              </div>
              <div class="flex w-1/2">
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Gestor Red Eléctrica' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Gestor Proyecto' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Dirección de Obra' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex w-1/2">
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Ingeniería de Proyecto' }}</div>
                  <div class="flex flex-row w-full">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Contrastista Principal' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Proveedores Equipamiento Principal' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
              </div>
              <div class="flex w-1/2">
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'Subcontratistas' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'RTB' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
                <div class="flex flex-col w-1/2">
                  <div class="flex flex-row w-full text-black font-bold">{{ 'COD' }}</div>
                  <div class="flex flex-row w-full text-blue font-bold">{{ 0 }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Panel 2 -->
          <div class="w-full mt-4 panel">
            <!-- <div class="flex flex-row w-full text-black font-bold justify-end text-xs"> -->
              <!-- <div>{{ $t('last_update') }}: {{ formatDate() }}</div>  -->
              <!-- Este panel no se cachea, mostrará hora actual -->
            <!-- </div> -->
            <div class="flex flex-row w-full text-blue font-bold">{{ 'Current Progress' }}</div>
            <div class="flex flex-row w-full relative">
              <div v-if="isLoadingDataGroup" class="w-full relative text-center">
                <b-loading :is-full-page="false" v-model="isLoadingDataGroup" :can-cancel="false"></b-loading>
                <!-- <scale-loader></scale-loader> -->
              </div>
              <bar-chart v-else :data="groupDataChart" suffix="%" :min="0" :max="100" :stacked="false" :download="true" height="500px"/>
            </div>
          </div>

          <div v-if="! isLoadingDataGroup" class="w-full mt-8 panel">
            <div style="width: 100%; text-align: center;">
              <div class="center">
                <label class="font-bold" style="font-size: 12px; color: #444">
                  <div style="height:10px; width: 40px; background: #F28E2B; display: inline-block"></div>
                  Total Executed: {{ totalProgress }}%
                </label>
                <label class="font-bold" style="font-size: 12px; color: #444">
                  <div style="height:10px; width: 40px; background: #4E79A7; display: inline-block"></div>
                  Total Scheduled: {{ totalScheduled }}%
                </label>
              </div>
              <div style="width: 100%; background-color: #f3f3f3; border: 1px solid #ddd; border-radius: 5px; margin-top: 5px;">
                <div :style="{'width': totalProgress + '%', 'background-color': '#F28E2B', 'height': 15+'px', 'border-radius': 5+'px'}"></div>
              </div>
              <div style="width: 100%; background-color: #f3f3f3; border: 1px solid #ddd; border-radius: 5px; margin-top: 5px;">
                <div :style="{ 'width': totalScheduled + '%', 'background-color': '#4E79A7', 'height': 15+'px', 'border-radius': 5+'px'}"></div>
              </div>
            </div>
          </div>

          <div v-if="! isLoadingDataGroup" class="w-full mt-8 panel relative">
            <b-table
              icon-pack="fas"
              :data="dataActivitiesGroup"
              :bordered="true"
              >
              <template>
                <b-table-column field="task" cell-class="w-5/12">
                  <template v-slot:header=""><span class="text-xs">{{ $t('task') }}</span></template>
                  <template v-slot="props">{{ props.row.name }}</template>
                </b-table-column>

                <b-table-column field="schedules_start">
                  <template v-slot:header=""><span class="text-xs">{{ 'Scheduled Start' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.current_start_date) }}</template>
                </b-table-column>

                <b-table-column field="schedules_end">
                  <template v-slot:header=""><span class="text-xs">{{ 'Scheduled End' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.current_end_date) }}</template>
                </b-table-column>

                <b-table-column field="current_start">
                  <template v-slot:header=""><span class="text-xs">{{ 'Current Start' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.real_start_date) }}</template>
                </b-table-column>

                <b-table-column field="current_end">
                  <template v-slot:header=""><span class="text-xs">{{ 'Current End' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.real_end_date) }}</template>
                </b-table-column>

                <b-table-column field="avance">
                  <template v-slot:header=""><span class="text-xs">% {{ 'Avance' }}</span></template>
                  <template v-slot="props">{{ props.row.progress_real }}</template>
                </b-table-column>
              </template>

              <template #empty>
                  <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>
            </b-table>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ '% Progress Project' }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="progressProject" :colors="['#3366CC', '#DC3913', '#129618']" suffix="%"/>
            </div>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ '% Progress cost control' }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="costControlProgress" suffix="€"/>
            </div>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ 'Venta planificada / Venta real' }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="ventaPlanificadaReal" suffix="€"/>
            </div>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ 'Costo planificado / Costo real + previsto' }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="costPlanificadoReal" suffix="€"/>
            </div>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('group_progress') }}</div>
            <div class="flex flex-row w-full">
              <column-chart :data="panel1" suffix="%" :min="0" :max="100"/>
            </div>
          </div>

          <!-- Gráficas dinámicas por grupo -->
          <div class="w-full mt-8 panel" v-for="group in dataCharsetsGroups" :key="'charset_'+group.id">
            <!-- Panel 3 -->
            <div class="w-full flex flex-col">
              <div class="flex flex-row w-full text-blue font-bold">{{ $t('progress_of') }} {{group.name}}</div>
              <div v-if="!group.loadDataset" class="flex flex-row w-full">
                <line-chart :suffix="'%'" :min="0" :max="100" :data="group.dataset" />
              </div>
              <div v-else class="w-full relative flex flex-row text-center" style="height: 80px;">
                <b-loading :is-full-page="false" v-model="group.loadDataset" :can-cancel="false"></b-loading>
                <!-- <scale-loader></scale-loader> -->
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>

    </b-tabs>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import VueChartkick from 'vue-chartkick'
  import Chart from 'chart.js'
  import moment from 'moment'
  import excelByActivityGroups from '·/components/reports/excel-by-activity-groups.vue'

  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  Vue.use(VueChartkick, {adapter: Chart})
  Vue.use(moment)

  export default {
    middleware: 'auth',
    title: 'project_home_page_title',
    subtitle: 'project_home_page_subtitle',
    menuOption: '2-1',

    components: {
      ScaleLoader,
      Treeselect,
      excelByActivityGroups,
    },

    data: () => ({
      filters: {
        entity_id: 0,
        location_id: 0,
        status_id: 1,
        tag_name: '',
        parent_group_id: null
      },
      isLoading: true,
      panel1: {
        info: [],
        users: []
      },
      dataActivitiesGroup: [],
      dataCharsetsGroups: [],
      mainActiveTab: 0,
      options: [],

      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          isDisabled: node.subgroups_num == 0,
          children: node.children,
        }
      },
      isLoadingExport: false,
      txtTooltipExport: null,


      totalScheduled: 0,
      totalProgress: 0,
      groupDataChart: [],
      costControlProgress: [],
      ventaPlanificadaReal: [],
      costPlanificadoReal: [],
      progressProject: [],

      isLoadingDataGroup: true
    }),

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    mounted() {

      this.preparePanel1()
      if (this.project == null) {

        this.$router.push('/projects');

      } else {
        this.getActivityGroupsList()

        this.getPanel2();
        this.getPanel3();
        this.getDataExtrafields();
        this.getPanelCost();
        this.getProgressProject();

        let pageInfo = {
          title: this.project.name,
          subtitle: this.$t('project_home_page_subtitle'),
          menuOption: '2-1',
        }

        this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
      }
    },

    methods: {

      preparePanel1() {
        this.panel1.info = [
          {
            "name": this.$t('activity').toUpperCase(),
            "total": 0
          },
          {
            "name": this.$t('measurements_loaded').toUpperCase(), // "MEDICIONES CREADAS",
            "total": 0
          },
          {
            "name": this.$t('issues').toUpperCase(),
            "total": 0
          },
          {
            "name": this.$t('flows_created').toUpperCase(), // "FLUJOS CREADOS",
            "total": 0
          },
          {
            "name": this.$t('uploaded_images').toUpperCase(), // "IMÁGENES CARGADAS",
            "total": 0
          },
          {
            "name": this.$t('uploaded_documents').toUpperCase(), // "DOCUMENTOS CARGADOS",
            "total": 0
          },
          {
            "name": this.$t('comments').toUpperCase(),
            "total": 0
          }
        ]
      },

      handleSelectGroup(value, instanceId) {
        this.filters.parent_group_id = value ?? null

        this.isLoading = true
        this.getPanel2()
        this.panel3 = []
        this.getPanel3()
      },

      async getActivityGroupsList(args = []) {

        const {data} = await axios.get(`/api/v2/project/${this.project.id}/activity/groups`, {params: args});

        if (data && data.groups) {
          this.options = data.groups;
        } else {
          this.$notify.error('error_loading_activities')
        }
      },

      async getDataExtrafields() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/getactivitygroupprogress';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.panel1 = response.data.data
            // self.panel1.forEach(element => {
            //   element[1] = Math.floor(Math.random() * 25) + 1;
            // });
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      async getPanel2() {
        let self = this
        self.isLoadingDataGroup = true;
        let url = '/api/v2/project/' + this.project.id + '/dashboard/getactivitygroupdata';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.dataActivitiesGroup = response.data.data.data
            self.groupDataChart = response.data.data.dataChart
            self.totalScheduled = response.data.data.totalScheduled
            self.totalProgress = response.data.data.totalProgress
            self.dataCharsetsGroups = response.data.data.groups
            self.isLoadingDataGroup = false
            self.loadDatasetGroup()
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      async getPanel3() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/photovoltaicpanel3';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.isLoading = false;
            self.panel3 = response.data.data
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      loadDatasetGroup() {
        let groups = this.dataCharsetsGroups.filter(group => group.loadDataset == true)
        if (groups.length > 0) {
          this.filters.entity_id = groups[0].id
          this.getDatasetGroup()
        }
      },

      async getDatasetGroup() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/getdatasetgroup';
        await axios.get(url, {params: this.filters})
          .then(response => {
            let data = response.data.data
            let g = self.dataCharsetsGroups.find(element => element.id == data.id )
            g.loadDataset = false
            g.dataset = data.data
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          })
          .finally(() => {

            setTimeout(() => {
              self.loadDatasetGroup()
            }, 500);
          });
      },

      async getProgressProject() {
        let self = this

        self.progressProject = [
          { name: "Programado", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        { name: "Real", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        {name: "Inspeccionado", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        ]
      },

      async getPanelCost() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/photovoltaicpanel3';
        // await axios.get(url, {params: this.filters})
        //   .then(response => {
        //     self.isLoading = false;
        //     self.panel3 = response.data.data
        //   })
        //   .catch((error) => {
        //     self.$notify.error(error.error_msg || 'error_retrieving_data')
        //   });

        self.costControlProgress = [
          { 'name': "Venta", data: {
            "Enero": 0,
            "Febrero": 0,
            "Marzo": 0,
            "Abril": 0,
            "Mayo": 0,
            "Junio": 0,
            "Julio": 0,
            "Agosto": 0,
            "Septiembre": 0,
            "Octubre": 0,
            "Noviembre": 0,
            "Diciembre": 0,
          } },
          { name: "Costo Planeado", data: {
            "Enero": 0,
            "Febrero": 0,
            "Marzo": 0,
            "Abril": 0,
            "Mayo": 0,
            "Junio": 0,
            "Julio": 0,
            "Agosto": 0,
            "Septiembre": 0,
            "Octubre": 0,
            "Noviembre": 0,
            "Diciembre": 0,
          }},
          { name: "Costo Real", data: {
            "Enero": 0,
            "Febrero": 0,
            "Marzo": 0,
            "Abril": 0,
            "Mayo": 0,
            "Junio": 0,
            "Julio": 0,
            "Agosto": 0,
            "Septiembre": 0,
            "Octubre": 0,
            "Noviembre": 0,
            "Diciembre": 0,
          }},
        ]

        self.ventaPlanificadaReal = [
          { name: "Venta Planificada", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        { name: "Venta Real", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        ],

        self.costPlanificadoReal = [
          { name: "Costo Planificado", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        { name: "Costo Real + previsto", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        ]
      },

      formatDate(date) {
        return moment(date).format('HH:mm (DD-MM)'); //'DD-MM-YYYY HH:mm'
      },

      async exportReport() {

        if( !this.isLoadingExport ){
          this.txtTooltipExport = this.$t('generating');

          this.$notify.success('generating_report');
          this.isLoadingExport = true;
          let self = this
          let url = '/api/v2/project/' + this.project.id + '/dashboard/exportphotovoltaic';
          await axios.get(url, {params: this.filters})
            .then(response => {
              window.open(response.data.data);

              self.$notify.success('report_generated')
              self.isLoadingExport = false;
              self.txtTooltipExport = this.$t('export')
            })
            .catch((error) => {
              //Se produjo un error
              console.log("Error loading exportación")
              self.$notify.error(error.error_msg || 'error_retrieving_data')
              self.isLoadingExport = false
              self.txtTooltipExport = this.$t('export')
            });
        }
      },

      formatDateCustom(dateString) {
        if (!dateString) {
          return null;
        }
        // Split the input string by "-"
        const [year, month, day] = dateString.split("-");
        // Return the date in DD-MM-YYYY format
        return `${day}-${month}-${year}`;
      }
    },
  };
  </script>
